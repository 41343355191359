<template>
  <section id="custom-list">
    <div class="list__main">
      <div class="list__header">
        <slot name="header">
          <div class="m-3 header__title">{{ title }}</div>
        </slot>
      </div>
      <div>
        <slot
          name="list_start"
          class="list__start"
        ></slot>
      </div>
      <div class="list__body">
        <slot name="list_body">
          <div
            v-for="item of items"
            :key="item.id"
            @click="selectRole(item)"
          >
            <slot
              name="item"
              :item="item"
            >
              <div
                class="list__item"
                :class="item.selected ? 'active' : ''"
              >
                <slot
                  name="item_name"
                  :item="item"
                >
                  <span>{{ item.name }}</span>
                </slot>
                <slot
                  name="actions"
                  :item="item"
                >
                  <div class="item__actions">
                    <b-dropdown
                      v-if="show_control"
                      no-caret
                      variant="none"
                      size="sm"
                      class="m-0 p-0"
                      style="height: 16px; width: 16px"
                    >
                      <template #button-content>
                        <img src="/img/icons/more_gray.svg" />
                      </template>
                      <b-dropdown-item @click="rename(item)"> Переименовать </b-dropdown-item>
                      <b-dropdown-item
                        variant="danger"
                        @click="delete_item(item)"
                      >
                        Удалить
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </slot>
              </div>
            </slot>
          </div>
        </slot>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'CustomList',
    props: {
      title: {
        type: String,
        default: 'jopa'
      },
      items: {
        type: Array,
        default: () => [
          {
            id: 1,
            name: 'а Тест1',
            selected: true,
            children: [
              { id: 1, name: 'а Тест1', children: [] },
              { id: 2, name: 'б Тест2' },
              { id: 3, name: 'в Тест3' }
            ]
          },
          {
            id: 2,
            name: 'б Тест2',
            children: [
              { id: 1, name: 'Тест1', children: [] },
              { id: 2, name: 'Тест2' },
              { id: 3, name: 'Тест3' }
            ]
          },
          {
            id: 3,
            name: 'в Тест3',
            children: [
              { id: 1, name: 'Тест1', children: [] },
              { id: 2, name: 'Тест2' },
              { id: 3, name: 'Тест3' }
            ]
          }
        ]
      },
      show_control: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      selectRole(item) {
        this.$emit('select-role', item)
      },
      rename(item) {
        this.$emit('rename', item)
      },
      delete_item(item) {
        this.$emit('delete_item', item)
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .dropdown > button {
      height: 16px !important;
    }

    .dropdown-menu > li > a {
      padding: 6px 12px !important;
    }
  }

  #custom-list {
    .list__main {
      display: flex;
      flex-direction: column;
      flex: 1;
      background: #ffffff;
      border: 1px solid #e1e1e1;
      border-radius: 4px;
      height: 100%;
    }

    .list__header {
      border-bottom: 0.5px solid #e1e1e1;
    }

    .header__title {
      font-size: 16px;
      color: #313131;
    }

    .list__body {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      padding-top: 8px;
    }

    .list__start {
      display: flex;
      flex-direction: column;
      padding-top: 8px;
    }

    .list__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      user-select: none;
      justify-content: space-between;
      padding: 8px 17px;
      transition: 200ms;
      cursor: pointer;

      &:hover {
        background: #f8f8f9;
        transition: 150ms;
      }
      &.active {
        background: #f8f8f9;
        transition: 200ms;

        &:after {
          content: '';
          background: #00a3ff;
          border-radius: 0 2px 2px 0;
          position: absolute;
          transition: 200ms;
          height: 21px;
          width: 2px;
          top: 8px;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }

      & > span {
        font-size: 14px;
        color: #313131;
        cursor: pointer;
      }
    }

    .item__actions {
      display: inline-flex;
      gap: 16px;

      > * {
        cursor: pointer;
      }
    }
  }
</style>
