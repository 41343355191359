var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sort-btn"
  }, [_vm.value === 'asc' ? _c('img', {
    attrs: {
      "src": "/img/icons/alphabet-sort-asc.svg"
    },
    on: {
      "click": function ($event) {
        return _vm.change_sort('desc');
      }
    }
  }) : _c('img', {
    attrs: {
      "src": "/img/icons/alphabet-sort-desc.svg"
    },
    on: {
      "click": function ($event) {
        return _vm.change_sort('asc');
      }
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }