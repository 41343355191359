var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tab', {
    attrs: {
      "title": "Сотрудник"
    }
  }, [_c('div', {
    staticClass: "mt-3 role__container",
    staticStyle: {
      "height": "73vh"
    }
  }, [_c('custom-list', {
    staticStyle: {
      "height": "100%",
      "flex": "1"
    },
    attrs: {
      "items": _vm.sorted_users,
      "title": "Сотрудники",
      "show_control": false
    },
    on: {
      "select-role": _vm.setUser
    },
    scopedSlots: _vm._u([{
      key: "list_start",
      fn: function () {
        return [_c('div', {
          staticClass: "top-search"
        }, [_c('b-input', {
          attrs: {
            "placeholder": "Поиск"
          },
          model: {
            value: _vm.search_user,
            callback: function ($$v) {
              _vm.search_user = $$v;
            },
            expression: "search_user"
          }
        }), _c('sort-btn', {
          model: {
            value: _vm.sort_names,
            callback: function ($$v) {
              _vm.sort_names = $$v;
            },
            expression: "sort_names"
          }
        })], 1)];
      },
      proxy: true
    }])
  }), _c('custom-list', {
    staticStyle: {
      "height": "100%",
      "flex": "1"
    },
    attrs: {
      "items": _vm.branch_list
    },
    scopedSlots: _vm._u([{
      key: "list_start",
      fn: function () {
        return [_c('div', {
          staticClass: "top-search"
        }, [_c('b-input', {
          attrs: {
            "placeholder": "Поиск"
          },
          model: {
            value: _vm.search_branch,
            callback: function ($$v) {
              _vm.search_branch = $$v;
            },
            expression: "search_branch"
          }
        }), _c('sort-btn', {
          model: {
            value: _vm.sort_shops,
            callback: function ($$v) {
              _vm.sort_shops = $$v;
            },
            expression: "sort_shops"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "header",
      fn: function () {
        return [_c('div', {
          staticClass: "rights__header m-3"
        }, [_c('span', [_vm._v("Магазины")])])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "list__item",
          class: item.selected ? 'active' : ''
        }, [_c('div', {
          staticClass: "switch-name",
          on: {
            "click": function ($event) {
              return _vm.openShop(item);
            }
          }
        }, [_c('b-checkbox', {
          attrs: {
            "checked": _vm.isChecked(item.id),
            "switch": ""
          },
          on: {
            "change": function (value) {
              return _vm.changeBranch(value, item);
            }
          }
        }), _c('span', [_vm._v(_vm._s(item.name))])], 1), _c('img', {
          attrs: {
            "src": "/img/icons/arrow.svg"
          }
        })])];
      }
    }])
  }), _c('custom-list', {
    staticStyle: {
      "height": "100%",
      "flex": "2"
    },
    attrs: {
      "title": "Действия"
    },
    scopedSlots: _vm._u([{
      key: "list_body",
      fn: function () {
        return [_c('div', {
          staticClass: "no-padding"
        }, [_c('b-table', {
          staticClass: "m-0 p-0",
          attrs: {
            "thead-class": "gray-header",
            "fields": _vm.action_fields,
            "items": _vm.price_lists
          },
          scopedSlots: _vm._u([{
            key: "head(read)",
            fn: function () {
              return [_c('div', {
                staticClass: "d-flex flex-column align-items-center"
              }, [_c('div', [_vm._v("Видеть")]), _c('e-switch', {
                staticStyle: {
                  "margin-top": "4px"
                },
                attrs: {
                  "triple": ""
                },
                on: {
                  "change": _vm.updateUserBranch
                },
                model: {
                  value: _vm.current_branch.price_list.read,
                  callback: function ($$v) {
                    _vm.$set(_vm.current_branch.price_list, "read", $$v);
                  },
                  expression: "current_branch.price_list.read"
                }
              })], 1)];
            },
            proxy: true
          }, {
            key: "head(edit)",
            fn: function () {
              return [_c('div', {
                staticClass: "d-flex flex-column align-items-center"
              }, [_c('div', [_vm._v("Изменять")]), _c('e-switch', {
                staticStyle: {
                  "margin-top": "4px"
                },
                attrs: {
                  "triple": ""
                },
                on: {
                  "change": _vm.updateUserBranch
                },
                model: {
                  value: _vm.current_branch.price_list.edit,
                  callback: function ($$v) {
                    _vm.$set(_vm.current_branch.price_list, "edit", $$v);
                  },
                  expression: "current_branch.price_list.edit"
                }
              })], 1)];
            },
            proxy: true
          }, {
            key: "cell(read)",
            fn: function (data) {
              return [_c('b-form-checkbox', {
                attrs: {
                  "checked": data.item.read
                },
                on: {
                  "change": function (value) {
                    return _vm.input_pc_read_object(value, data.item);
                  }
                }
              })];
            }
          }, {
            key: "cell(edit)",
            fn: function (data) {
              return [_c('b-form-checkbox', {
                attrs: {
                  "checked": data.item.edit
                },
                on: {
                  "change": function (value) {
                    return _vm.input_pc_edit_object(value, data.item);
                  }
                }
              })];
            }
          }])
        }), _c('b-table', {
          staticClass: "m-0 p-0",
          attrs: {
            "thead-class": "gray-header",
            "fields": _vm.action_storage_fields,
            "items": _vm.storages
          },
          scopedSlots: _vm._u([{
            key: "head(read)",
            fn: function () {
              return [_c('div', {
                staticClass: "d-flex flex-column align-items-center"
              }, [_c('div', [_vm._v("Видеть")]), _c('e-switch', {
                staticStyle: {
                  "margin-top": "4px"
                },
                attrs: {
                  "triple": ""
                },
                on: {
                  "change": _vm.updateUserBranch
                },
                model: {
                  value: _vm.current_branch.storages.read,
                  callback: function ($$v) {
                    _vm.$set(_vm.current_branch.storages, "read", $$v);
                  },
                  expression: "current_branch.storages.read"
                }
              })], 1)];
            },
            proxy: true
          }, {
            key: "head(document_flow)",
            fn: function () {
              return [_c('div', {
                staticClass: "d-flex flex-column align-items-center"
              }, [_c('div', [_vm._v("Документы")]), _c('e-switch', {
                staticStyle: {
                  "margin-top": "4px"
                },
                attrs: {
                  "triple": ""
                },
                on: {
                  "change": _vm.updateUserBranch
                },
                model: {
                  value: _vm.current_branch.storages.document_flow,
                  callback: function ($$v) {
                    _vm.$set(_vm.current_branch.storages, "document_flow", $$v);
                  },
                  expression: "current_branch.storages.document_flow"
                }
              })], 1)];
            },
            proxy: true
          }, {
            key: "cell(read)",
            fn: function (data) {
              return [_c('b-form-checkbox', {
                attrs: {
                  "checked": data.item.read
                },
                on: {
                  "change": function (value) {
                    return _vm.input_storage_object(value, data.item);
                  }
                }
              })];
            }
          }, {
            key: "cell(document_flow)",
            fn: function (data) {
              return [_c('b-form-checkbox', {
                attrs: {
                  "checked": data.item.document_flow
                },
                on: {
                  "change": function (value) {
                    return _vm.input_storage_doc_object(value, data.item);
                  }
                }
              })];
            }
          }])
        })], 1)];
      },
      proxy: true
    }])
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }