<template>
  <div class="sort-btn">
    <img
      v-if="value === 'asc'"
      src="/img/icons/alphabet-sort-asc.svg"
      @click="change_sort('desc')"
    />
    <img
      v-else
      src="/img/icons/alphabet-sort-desc.svg"
      @click="change_sort('asc')"
    />
  </div>
</template>

<script>
  export default {
    name: 'SortBtn',
    props: ['value'],
    methods: {
      change_sort(val) {
        this.$emit('input', val)
      }
    }
  }
</script>

<style scoped>
  .sort-btn {
    cursor: pointer;
  }
</style>
