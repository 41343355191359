<template>
  <section id="access-rights">
    <!-- <h1 class="mb-3">Права доступа</h1> -->
    <b-tabs
      nav-class="min-tabs-width"
      active-nav-item-class="text-dark font-weight-bold"
    >
      <role-tab />
      <employee-tab />
      <!--      <b-tab title="Магазины" />-->
    </b-tabs>
  </section>
</template>

<script>
  import RoleTab from '@/views/settings/components/access_rights/RoleTab.vue'
  import EmployeeTab from '@/views/settings/components/access_rights/EmployeeTab.vue'

  export default {
    name: 'AccessRights',
    components: { EmployeeTab, RoleTab }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .min-tabs-width {
      display: inline-flex !important;
    }
  }
</style>
