var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tab', {
    attrs: {
      "title": "Роль"
    }
  }, [_c('edit-role-modal', {
    attrs: {
      "role": _vm.edited_role
    },
    on: {
      "update": _vm.updateRole
    }
  }), _c('div', {
    staticClass: "mt-3 role__container",
    staticStyle: {
      "height": "73vh"
    }
  }, [_c('custom-list', {
    staticStyle: {
      "height": "100%",
      "flex": "1"
    },
    attrs: {
      "items": _vm.roles
    },
    on: {
      "select-role": _vm.selectRole,
      "rename": _vm.renameRole,
      "delete_item": _vm.deleteRole
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('div', {
          staticClass: "role__header m-3"
        }, [_c('span', [_vm._v("Список ролей")]), _c('span', {
          on: {
            "click": _vm.add_role
          }
        }, [_c('img', {
          attrs: {
            "height": "16",
            "width": "16",
            "src": "/img/icons/plus_dark.svg"
          }
        })])])];
      },
      proxy: true
    }])
  }), _c('custom-list', {
    staticStyle: {
      "height": "100%",
      "flex": "1"
    },
    attrs: {
      "items": _vm.rules
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('div', {
          staticClass: "rights__header m-3"
        }, [_c('span', [_vm._v("Все права роли")])])];
      },
      proxy: true
    }, {
      key: "item",
      fn: function (_ref) {
        var _item$children;

        var item = _ref.item;
        return [_c('div', {
          staticClass: "dropdown-list",
          on: {
            "click": function ($event) {
              return _vm.openRules(item, 'dropdown-list');
            }
          }
        }, [_c('img', {
          style: item.selected ? 'transform: none' : '',
          attrs: {
            "src": "/img/icons/icon-sorting.svg"
          }
        }), _c('span', [_vm._v(_vm._s(item.name))])]), _c('transition', {
          attrs: {
            "duration": "120",
            "mode": "in-out"
          }
        }, [(_item$children = item.children) !== null && _item$children !== void 0 && _item$children.length && item.selected ? _c('div', {
          staticClass: "child-list"
        }, _vm._l(item.children, function (child) {
          return _c('div', {
            key: child.id,
            staticClass: "child-item",
            class: child.selected ? 'active' : '',
            staticStyle: {
              "cursor": "pointer"
            },
            on: {
              "click": function ($event) {
                return _vm.selectChildren(item, child);
              }
            }
          }, [_c('div', {
            staticClass: "name-cont"
          }, [_c('b-checkbox', {
            attrs: {
              "switch": "",
              "checked": _vm.selectedRole.permission_by_resource(child, 'read')
            },
            on: {
              "change": function (val) {
                return _vm.setPermission(val, 'read');
              }
            }
          }), _c('span', [_vm._v(_vm._s(child.name))])], 1), _c('img', {
            staticStyle: {
              "cursor": "pointer"
            },
            attrs: {
              "src": "/img/icons/arrow.svg"
            }
          })]);
        }), 0) : _vm._e()])];
      }
    }])
  }), _c('custom-list', {
    staticStyle: {
      "height": "100%",
      "flex": "2"
    },
    attrs: {
      "title": "Действия"
    },
    scopedSlots: _vm._u([{
      key: "list_body",
      fn: function () {
        return [_c('div', {
          staticClass: "no-padding"
        }, [_c('b-table', {
          staticClass: "m-0 p-0",
          attrs: {
            "thead-class": "gray-header",
            "fields": _vm.action_fields,
            "items": _vm.action_items
          },
          scopedSlots: _vm._u([{
            key: "head(status)",
            fn: function () {
              return [_c('div', {
                staticClass: "center"
              }, [_c('div', [_vm._v("Да")]), _c('i-switch', {
                attrs: {
                  "size": "small",
                  "value": _vm.selectedRole.all_permissions(_vm.selectedResource),
                  "disabled": !_vm.selectedResource.permission['read']
                },
                on: {
                  "on-change": _vm.changePermissions
                }
              })], 1)];
            },
            proxy: true
          }, {
            key: "cell(status)",
            fn: function (_ref2) {
              var item = _ref2.item;
              return [_c('b-checkbox', {
                staticClass: "center",
                attrs: {
                  "checked": _vm.selectedResource.permission[item.operation],
                  "disabled": !_vm.selectedResource.permission.read
                },
                on: {
                  "change": function (val) {
                    return _vm.setPermission(val, item.operation);
                  }
                }
              })];
            }
          }])
        })], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }