<template>
  <b-tab title="Сотрудник">
    <div
      style="height: 73vh"
      class="mt-3 role__container"
    >
      <custom-list
        style="height: 100%; flex: 1"
        :items="sorted_users"
        title="Сотрудники"
        :show_control="false"
        @select-role="setUser"
      >
        <template #list_start>
          <div class="top-search">
            <b-input
              v-model="search_user"
              placeholder="Поиск"
            />
            <sort-btn v-model="sort_names" />
          </div>
        </template>
      </custom-list>
      <custom-list
        :items="branch_list"
        style="height: 100%; flex: 1"
      >
        <template #list_start>
          <div class="top-search">
            <b-input
              v-model="search_branch"
              placeholder="Поиск"
            />
            <sort-btn v-model="sort_shops" />
          </div>
        </template>

        <template #header>
          <div class="rights__header m-3">
            <!--            <b-checkbox switch />-->
            <span>Магазины</span>
          </div>
        </template>

        <template #item="{ item }">
          <div
            class="list__item"
            :class="item.selected ? 'active' : ''"
          >
            <div
              class="switch-name"
              @click="openShop(item)"
            >
              <b-checkbox
                :checked="isChecked(item.id)"
                switch
                @change="(value) => changeBranch(value, item)"
              />
              <span>{{ item.name }}</span>
            </div>
            <img src="/img/icons/arrow.svg" />
          </div>
        </template>
      </custom-list>
      <custom-list
        style="height: 100%; flex: 2"
        title="Действия"
      >
        <template #list_body>
          <div class="no-padding">
            <b-table
              class="m-0 p-0"
              thead-class="gray-header"
              :fields="action_fields"
              :items="price_lists"
            >
              <template #head(read)>
                <div class="d-flex flex-column align-items-center">
                  <div>Видеть</div>
                  <e-switch
                    v-model="current_branch.price_list.read"
                    style="margin-top: 4px"
                    triple
                    @change="updateUserBranch"
                  />
                </div>
              </template>
              <template #head(edit)>
                <div class="d-flex flex-column align-items-center">
                  <div>Изменять</div>
                  <e-switch
                    v-model="current_branch.price_list.edit"
                    style="margin-top: 4px"
                    triple
                    @change="updateUserBranch"
                  />
                </div>
              </template>
              <template #cell(read)="data">
                <b-form-checkbox
                  :checked="data.item.read"
                  @change="(value) => input_pc_read_object(value, data.item)"
                />
              </template>
              <template #cell(edit)="data">
                <b-form-checkbox
                  :checked="data.item.edit"
                  @change="(value) => input_pc_edit_object(value, data.item)"
                />
              </template>
            </b-table>
            <b-table
              class="m-0 p-0"
              thead-class="gray-header"
              :fields="action_storage_fields"
              :items="storages"
            >
              <template #head(read)>
                <div class="d-flex flex-column align-items-center">
                  <div>Видеть</div>
                  <e-switch
                    v-model="current_branch.storages.read"
                    style="margin-top: 4px"
                    triple
                    @change="updateUserBranch"
                  />
                </div>
              </template>
              <template #head(document_flow)>
                <div class="d-flex flex-column align-items-center">
                  <div>Документы</div>
                  <e-switch
                    v-model="current_branch.storages.document_flow"
                    style="margin-top: 4px"
                    triple
                    @change="updateUserBranch"
                  />
                </div>
              </template>
              <template #cell(read)="data">
                <b-form-checkbox
                  :checked="data.item.read"
                  @change="(value) => input_storage_object(value, data.item)"
                />
              </template>
              <template #cell(document_flow)="data">
                <b-form-checkbox
                  :checked="data.item.document_flow"
                  @change="(value) => input_storage_doc_object(value, data.item)"
                />
              </template>
            </b-table>
          </div>
        </template>
      </custom-list>
    </div>
  </b-tab>
</template>

<script>
  import CustomList from '@/views/settings/components/access_rights/CustomList.vue'
  import { UserModelView } from '@/models/user.model'
  import { mapGetters } from 'vuex'
  import ESwitch from '@/views/settings/components/access_rights/ESwitch.vue'
  import { UserBranchModel } from '@/models/user-branch.model'
  import SortBtn from '@/views/settings/components/SortBtn.vue'
  export default {
    name: 'EmployeeTab',
    components: { SortBtn, ESwitch, CustomList },
    apollo: {
      UserFromCompany: {
        query: require('../../gql/GetUsers.graphql'),
        result({ data }) {
          this.users = data.UserFromCompany.filter((el) => el.type !== 'agent').map((el) => {
            return {
              ...el,
              name:
                el.last_name +
                ' ' +
                (el.first_name ? el.first_name[0] + '.' : '') +
                (el.second_name ? el.second_name[0] + '.' : '')
            }
          })
          this.setUser(this.sorted_users[0])
        }
      }
    },
    data() {
      return {
        sort_names: 'asc',
        sort_shops: 'asc',
        search_user: '',
        search_branch: '',
        users: [],
        action_items: [],
        current_user: new UserModelView(),
        current_branch: new UserBranchModel(),
        action_fields: [
          { key: 'action', label: 'Прайс лист', thStyle: 'width:100%' },
          {
            key: 'read',
            label: 'Да',
            tdClass: 'center',
            thStyle: 'min-width:105px',
            tdStyle: 'text-align: center'
          },
          {
            key: 'edit',
            label: 'Да',
            tdClass: 'center',
            thStyle: 'min-width:105px'
          }
        ],
        action_storage_fields: [
          { key: 'action', label: 'Места хранения', thStyle: 'width:100%' },
          {
            key: 'read',
            label: 'Да',
            tdClass: 'center',
            thStyle: 'min-width:105px;text-align: center',
            tdStyle: 'text-align: center'
          },
          {
            key: 'document_flow',
            label: 'Да',
            tdClass: 'center',
            thStyle: 'min-width:105px;text-align: center',
            style: 'text-align: center'
          }
        ],
        test_items: [
          {
            id: 1,
            name: 'а Тест1',
            selected: true,
            children: [
              { id: 1, name: 'а Тест1', children: [] },
              { id: 2, name: 'б Тест2' },
              { id: 3, name: 'в Тест3' }
            ]
          },
          {
            id: 2,
            name: 'г Тест2',
            children: [
              { id: 1, name: 'Тест1', children: [] },
              { id: 2, name: 'Тест2' },
              { id: 3, name: 'Тест3' }
            ]
          },
          {
            id: 4,
            name: 'в Тест2',
            children: [
              { id: 1, name: 'Тест1', children: [] },
              { id: 2, name: 'Тест2' },
              { id: 3, name: 'Тест3' }
            ]
          },
          {
            id: 3,
            name: 'б Тест3',
            children: [
              { id: 1, name: 'Тест1', children: [] },
              { id: 2, name: 'Тест2' },
              { id: 3, name: 'Тест3' }
            ]
          }
        ],
        storages: [],
        price_lists: [],
        userBranches: []
      }
    },
    methods: {
      async setUser(item) {
        this.current_user = new UserModelView(item)
        item.selected = true
        this.users = this.users.map((el) => {
          el.selected = el.id === item.id
          return el
        })
        const { data } = await this.$apollo.query({
          query: require('../../gql/ReadUserBranches.graphql'),
          variables: {
            input: {
              user: item.id
            }
          }
        })
        this.userBranches = data.ReadUserBranches || []
        this.openShop(this.branch_list[0])
      },
      changeBranch(value, item) {
        if (value) this.createUserBranch(this.current_user.id, item.id)
        else this.removeUserBranch(this.current_user.id, item.id)
      },
      removeUserBranch(user, branch) {
        this.$apollo.mutate({
          mutation: require('../../gql/RemoveUserBranch.graphql'),
          variables: {
            input: {
              branch: branch,
              user: user
            }
          }
        })
      },
      createUserBranch(user, branch) {
        this.$apollo.mutate({
          mutation: require('../../gql/CreateUserBranch.graphql'),
          variables: {
            input: {
              branch: branch,
              user: user
            }
          }
        })
      },

      async openShop(item) {
        // const br = this.userBranches.find((el) => el.branch.id === item.id)
        const req = await this.$apollo.query({
          query: require('../../gql/ReadUserBranch.graphql'),
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              user: this.current_user.id,
              branch: item.id
            }
          }
        })
        this.current_branch = new UserBranchModel({
          ...req.data?.ReadUserBranch,
          branch: item
        })
        const { data } = await this.$apollo.query({
          query: require('../../gql/PriceLists.graphql'),
          variables: {
            branch: this.current_branch.branch.id
          }
        })
        this.price_lists = data.PriceLists?.map((el) => {
          const obj = this.current_branch.objects?.find((obj) => obj.price_list === el.id)
          return {
            action: el.type,
            id: el.id,
            read: obj?.read,
            edit: obj?.edit
          }
        })
        const storages = await this.$apollo.query({
          query: require('../../gql/getStorages.gql'),
          variables: {
            branch: this.current_branch.branch.id
          }
        })
        this.storages = storages.data.Storages?.map((el) => {
          const obj = this.current_branch.objects?.find((obj) => obj.storage === el.id)
          return {
            action: el.name,
            id: el.id,
            read: obj?.read,
            document_flow: obj?.document_flow
          }
        })
      },
      change_sort(sort) {
        if (sort === 'asc') this.test_items.sort((a, b) => a.name.localeCompare(b.name))
        else this.test_items.reverse()
        this.sort_names = sort
      },
      isChecked(branch) {
        return this.userBranches?.some((el) => el.branch?.id === branch)
      },
      async updateUserBranch() {
        await this.$apollo.mutate({
          mutation: require('../../gql/CreateUserBranch.graphql'),
          variables: {
            input: {
              branch: this.current_branch.branch.id,
              user: this.current_user.id,
              storages: this.current_branch.storages,
              price_list: this.current_branch.price_list
            }
          }
        })
        this.openShop(this.current_branch.branch)
      },
      async input_storage_object(val, data) {
        await this.$apollo.mutate({
          mutation: require('../../gql/CreateUserObject.graphql'),
          variables: {
            input: {
              branch: this.current_branch.branch.id,
              user: this.current_user.id,
              storage: data.id,
              read: val
            }
          }
        })
        this.openShop(this.current_branch.branch)
      },
      async input_storage_doc_object(val, data) {
        await this.$apollo.mutate({
          mutation: require('../../gql/CreateUserObject.graphql'),
          variables: {
            input: {
              branch: this.current_branch.branch.id,
              user: this.current_user.id,
              storage: data.id,
              document_flow: val
            }
          }
        })
        this.openShop(this.current_branch.branch)
      },
      async input_pc_read_object(val, data) {
        await this.$apollo.mutate({
          mutation: require('../../gql/CreateUserObject.graphql'),
          variables: {
            input: {
              branch: this.current_branch.branch.id,
              user: this.current_user.id,
              price_list: data.id,
              read: val
            }
          }
        })
        this.openShop(this.current_branch.branch)
      },
      async input_pc_edit_object(val, data) {
        await this.$apollo.mutate({
          mutation: require('../../gql/CreateUserObject.graphql'),
          variables: {
            input: {
              branch: this.current_branch.branch.id,
              user: this.current_user.id,
              price_list: data.id,
              edit: val
            }
          }
        })
        this.openShop(this.current_branch.branch)
      }
    },
    computed: {
      ...mapGetters({
        branchList: 'settings/getBranchList'
      }),
      branch_list() {
        const is_asc = this.sort_shops === 'asc' ? 1 : -1
        const branch_list = this.branchList
        branch_list.sort((a, b) => (a.name > b.name ? 1 * is_asc : a.name === b.name ? 0 : -1 * is_asc))
        return branch_list
          .filter((el) => el.name.toLowerCase().includes(this.search_branch.toLowerCase()))
          .map((el) => {
            return {
              ...el,
              selected: this.current_branch.branch.id === el.id
            }
          })
      },
      sorted_users() {
        const is_asc = this.sort_names === 'asc' ? 1 : -1
        const users = this.users
        users.sort((a, b) => (a.name > b.name ? 1 * is_asc : -1 * is_asc))
        return users
          .filter((el) => el.name.toLowerCase().includes(this.search_user.toLowerCase()))
          .map((el) => {
            return {
              ...el,
              selected: this.current_user.id === el.id
            }
          })
      }
    }
  }
</script>

<style scoped lang="scss">
  .role__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > span {
      font-size: 16px;
      color: #313131;

      & > img {
        cursor: pointer;
      }
    }
  }

  .rights__header {
    display: inline-flex;
    gap: 6px;

    & > span {
      font-size: 16px;
      color: #313131;
    }
  }

  .role__container {
    width: 100%;
    height: 73vh;
    display: inline-flex;
    gap: 16px;
  }

  .dropdown-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10.5px;
    position: relative;
    padding: 8px 17px;

    & > span {
      font-size: 14px;
      color: #313131;
      cursor: pointer;
    }

    & > img {
      cursor: pointer;
      transform: rotate(-90deg);
      scale: 1.2;
    }
  }

  .child-list {
    display: flex;
    flex-direction: column;
  }

  .child-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 17px;
  }

  .name-cont {
    display: inline-flex;
    align-items: center;

    & > span {
      font-size: 14px;
      color: #313131;
    }
  }

  .top-search {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 8px 16px 8px 16px;

    & > img {
      cursor: pointer;
      transition: 200ms;
    }
  }

  .switch-name {
    display: inline-flex;
    align-items: center;

    & > span {
      color: #313131;
    }
  }
</style>
