var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "custom-list"
    }
  }, [_c('div', {
    staticClass: "list__main"
  }, [_c('div', {
    staticClass: "list__header"
  }, [_vm._t("header", function () {
    return [_c('div', {
      staticClass: "m-3 header__title"
    }, [_vm._v(_vm._s(_vm.title))])];
  })], 2), _c('div', [_vm._t("list_start")], 2), _c('div', {
    staticClass: "list__body"
  }, [_vm._t("list_body", function () {
    return _vm._l(_vm.items, function (item) {
      return _c('div', {
        key: item.id,
        on: {
          "click": function ($event) {
            return _vm.selectRole(item);
          }
        }
      }, [_vm._t("item", function () {
        return [_c('div', {
          staticClass: "list__item",
          class: item.selected ? 'active' : ''
        }, [_vm._t("item_name", function () {
          return [_c('span', [_vm._v(_vm._s(item.name))])];
        }, {
          "item": item
        }), _vm._t("actions", function () {
          return [_c('div', {
            staticClass: "item__actions"
          }, [_vm.show_control ? _c('b-dropdown', {
            staticClass: "m-0 p-0",
            staticStyle: {
              "height": "16px",
              "width": "16px"
            },
            attrs: {
              "no-caret": "",
              "variant": "none",
              "size": "sm"
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('img', {
                  attrs: {
                    "src": "/img/icons/more_gray.svg"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            on: {
              "click": function ($event) {
                return _vm.rename(item);
              }
            }
          }, [_vm._v(" Переименовать ")]), _c('b-dropdown-item', {
            attrs: {
              "variant": "danger"
            },
            on: {
              "click": function ($event) {
                return _vm.delete_item(item);
              }
            }
          }, [_vm._v(" Удалить ")])], 1) : _vm._e()], 1)];
        }, {
          "item": item
        })], 2)];
      }, {
        "item": item
      })], 2);
    });
  })], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }