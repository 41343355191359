<template>
  <section id="edit-role-modal">
    <b-modal
      id="edit-role-modal"
      centered
    >
      <template #modal-header="{ close }">
        <h5>{{ role.id ? 'Редактировать роль' : 'Новая роль' }}</h5>
        <b-button
          class="close"
          @click="close"
        >
          <img
            src="/img/icons/modal_close.svg"
            alt=""
          />
        </b-button>
      </template>
      <label>Название</label>
      <b-input
        v-model="name"
        placeholder="Введите название роли"
      />
      <template #modal-footer="{ close }">
        <div class="d-flex w-50">
          <b-button
            variant="primary"
            @click="saveRole"
          >
            Сохранить
          </b-button>
          <b-button
            variant="outline-primary"
            @click="close"
          >
            Отмена
          </b-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
  import { RoleModel } from '@/models/role.model'

  export default {
    name: 'EditRoleModal',
    props: {
      role: {
        type: RoleModel,
        default: () => new RoleModel()
      }
    },
    data() {
      return {
        name: ''
      }
    },
    watch: {
      'role.name'(newVal) {
        this.name = newVal
      }
    },
    mounted() {
      this.name = this.role.name
    },
    methods: {
      async saveRole() {
        await this.$apollo.mutate({
          mutation: require(!this.role.id ? '../../gql/CreateRole.graphql' : '../../gql/UpdateRole.graphql'),
          variables: {
            input: {
              id: this.role.id,
              name: this.name
            }
          }
        })

        this.$emit('update')
        this.$bvModal.hide('edit-role-modal')
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .modal-footer {
      border-top: 1px solid #e1e1e1;
      padding: 12px 22px;
    }
  }
</style>
