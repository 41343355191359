<template>
  <b-tab title="Роль">
    <edit-role-modal
      :role="edited_role"
      @update="updateRole"
    />
    <div
      style="height: 73vh"
      class="mt-3 role__container"
    >
      <custom-list
        style="height: 100%; flex: 1"
        :items="roles"
        @select-role="selectRole"
        @rename="renameRole"
        @delete_item="deleteRole"
      >
        <template #header>
          <div class="role__header m-3">
            <span>Список ролей</span>
            <span @click="add_role">
              <img
                height="16"
                width="16"
                src="/img/icons/plus_dark.svg"
              />
            </span>
          </div>
        </template>
      </custom-list>
      <custom-list
        style="height: 100%; flex: 1"
        :items="rules"
      >
        <template #header>
          <div class="rights__header m-3">
            <!--            <b-checkbox switch />-->
            <span>Все права роли</span>
          </div>
        </template>

        <template #item="{ item }">
          <div
            class="dropdown-list"
            @click="openRules(item, 'dropdown-list')"
          >
            <img
              src="/img/icons/icon-sorting.svg"
              :style="item.selected ? 'transform: none' : ''"
            />
            <span>{{ item.name }}</span>
          </div>
          <transition
            duration="120"
            mode="in-out"
          >
            <div
              v-if="item.children?.length && item.selected"
              class="child-list"
            >
              <div
                v-for="child of item.children"
                :key="child.id"
                class="child-item"
                style="cursor: pointer"
                :class="child.selected ? 'active' : ''"
                @click="selectChildren(item, child)"
              >
                <div class="name-cont">
                  <b-checkbox
                    switch
                    :checked="selectedRole.permission_by_resource(child, 'read')"
                    @change="(val) => setPermission(val, 'read')"
                  />
                  <span>{{ child.name }}</span>
                </div>
                <img
                  src="/img/icons/arrow.svg"
                  style="cursor: pointer"
                />
              </div>
            </div>
          </transition>
        </template>
      </custom-list>
      <custom-list
        style="height: 100%; flex: 2"
        title="Действия"
      >
        <template #list_body>
          <div class="no-padding">
            <b-table
              class="m-0 p-0"
              thead-class="gray-header"
              :fields="action_fields"
              :items="action_items"
            >
              <template #head(status)>
                <div class="center">
                  <div>Да</div>
                  <!--                  <b-checkbox switch class="mt-1" />-->
                  <i-switch
                    size="small"
                    :value="selectedRole.all_permissions(selectedResource)"
                    :disabled="!selectedResource.permission['read']"
                    @on-change="changePermissions"
                  />
                </div>
              </template>

              <template #cell(status)="{ item }">
                <b-checkbox
                  :checked="selectedResource.permission[item.operation]"
                  :disabled="!selectedResource.permission.read"
                  class="center"
                  @change="(val) => setPermission(val, item.operation)"
                />
              </template>
            </b-table>
          </div>
        </template>
      </custom-list>
    </div>
  </b-tab>
</template>

<script>
  import CustomList from '@/views/settings/components/access_rights/CustomList.vue'
  import EditRoleModal from '@/views/settings/components/access_rights/EditRoleModal.vue'
  import { PermissionModel, ResourceModel, RoleModelView } from '@/models/role.model'

  export default {
    name: 'RoleTab',
    components: { EditRoleModal, CustomList },
    data() {
      return {
        firstLoading: true,
        action_fields: [
          { key: 'action', label: 'Основные действия' },
          { key: 'status', label: 'Да', thClass: 'center' }
        ],
        selectedRole: new RoleModelView(),
        selectedResource: new ResourceModel(),
        action_items: [
          {
            action: 'Может добавлять',
            operation: 'create'
          },
          {
            action: 'Может изменять',
            operation: 'update'
          },
          {
            action: 'Может удалять',
            operation: 'delete'
          }
        ],
        edited_role: new RoleModelView(),
        roles: [],
        rules: [
          {
            id: 1,
            name: 'Структура компании',
            children: [
              {
                id: 'shops',
                type: 'branch',
                name: 'Магазины'
              },
              {
                id: 'storages',
                type: 'storage',
                name: 'Места хранения'
              },
              {
                id: 'pricelists',
                type: 'price_list',
                name: 'Прайс-листы'
              }
            ]
          },
          {
            id: 2,
            name: 'Справочники',
            children: [
              { id: 'product', type: 'product', name: 'Товары' },
              {
                id: 'category',
                name: 'Группы товаров',
                type: 'category'
              },
              {
                id: 'supplier',
                name: 'Контрагенты',
                type: 'supplier'
              },
              {
                id: 'manufacture',
                name: 'Производители',
                type: 'manufacture'
              },
              {
                id: 'country',
                name: 'Страны',
                type: 'country'
              }
            ]
          },
          {
            id: 3,
            name: 'Документы',
            children: [
              {
                id: 'order',
                type: 'order',
                name: 'Заказы'
              },
              {
                id: 'incoming',
                type: 'incoming',
                name: 'Приходы'
              },
              {
                id: 'outgoing',
                type: 'outgoing',
                name: 'Расходы'
              },
              {
                id: 'movement',
                type: 'movement',
                name: 'Перемещения'
              },
              {
                id: 'revaluation',
                type: 'revaluation',
                name: 'Переоценка'
              },
              {
                id: 'invoice',
                type: 'invoice',
                name: 'Счет'
              },
              {
                id: 'inventarisation',
                type: 'inventarisation',
                name: 'Инвентаризация'
              }
            ]
          },
          {
            id: 4,
            name: 'Администрирование',
            children: [
              {
                id: 'role',
                type: 'role',
                name: 'Роли'
              },
              {
                id: 'user',
                type: 'user',
                name: 'Пользователи'
              }
            ]
          }
        ]
      }
    },

    apollo: {
      Roles: {
        query: require('../../gql/access_rights/Roles.graphql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          if (!data?.Roles) return
          data?.Roles?.sort((a, b) => (a.name > b.name ? 1 : -1))
          this.roles =
            data?.Roles?.map(
              (el) =>
                new RoleModelView({
                  ...el,
                  selected: this.selectedRole.id === el.id
                })
            ) || []
          this.selectedRole = this.roles.find((obj) => obj.id === this.selectedRole.id)
          if (this.roles?.[0] && this.firstLoading) this.selectRole(this.roles[0])
          this.firstLoading = false
        }
      }
    },
    methods: {
      add_role() {
        this.edited_role = new RoleModelView()
        this.$bvModal.show('edit-role-modal')
      },
      selectRole(item) {
        this.roles = this.roles.map((el) => {
          return {
            ...el,
            selected: el.id === item.id
          }
        })
        this.selectedRole = new RoleModelView(item)
        this.openRules(this.rules[0], true)
      },
      renameRole(item) {
        this.edited_role = new RoleModelView(item)
        this.$bvModal.show('edit-role-modal')
      },
      deleteRole() {},
      openRules(item, is_auto) {
        if (item.selected && !is_auto) {
          return (item.selected = false)
        }
        this.selectChildren(item, this.selectedResource.type ? this.selectedResource : item.children[0], true)
      },
      selectChildren(item, child, open) {
        if (
          this.rules.find((obj) => {
            if (obj.id === item.id && obj.selected) {
              obj.selected = false
              return obj
            }
          }) &&
          open
        ) {
          this.rules = this.rules.map((obj) => {
            if (obj.id === item.id && obj.selected) {
              obj.selected = false
            }
            return obj
          })
          return
        }
        item.selected = true
        this.rules = this.rules.map((el) => {
          if (el.id === item.id) {
            el.children = el?.children.map((itm) => {
              itm.selected = itm?.id === child?.id
              return itm
            })
          }
          return el
        })
        const resource = this.selectedRole.resources?.find((el) => el.type === child.type)
        this.selectedResource = new ResourceModel({ ...child, ...resource })
      },
      async changePermissions(val) {
        await this.$apollo.mutate({
          mutation: require('../../gql/access_rights/UpdateRoleResource.graphql'),
          variables: {
            input: {
              permission: {
                create: val,
                update: val,
                delete: val
              },
              role: this.selectedRole.id,
              type: this.selectedResource.type
            }
          }
        })
        this.selectedResource.permission = new PermissionModel({
          ...this.selectedResource.permission,
          create: val,
          update: val,
          delete: val
        })
        await this.$apollo.queries.Roles.refresh()
      },
      async setPermission(val, operation) {
        let permission =
          operation == 'read'
            ? {
                read: val,
                create: val,
                update: val,
                delete: val
              }
            : { [operation]: val }
        await this.$apollo.mutate({
          mutation: require('../../gql/access_rights/UpdateRoleResource.graphql'),
          variables: {
            input: {
              permission: permission,
              role: this.selectedRole.id,
              type: this.selectedResource.type
            }
          }
        })
        this.selectedResource.permission = new PermissionModel({
          ...this.selectedResource.permission,
          ...permission
        })
        await this.$apollo.queries.Roles.refresh()
      },
      async updateRole() {
        await this.$apollo.queries.Roles.refresh()
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .gray-header {
      & > tr {
        & > th {
          & > div {
            color: #313131;
          }
          background: #f8f8f9 !important;
          border: none !important;
        }
      }
    }
  }
  .role__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > span {
      font-size: 16px;
      color: #313131;

      & > img {
        cursor: pointer;
      }
    }
  }

  .rights__header {
    display: inline-flex;
    gap: 6px;

    & > span {
      font-size: 16px;
      color: #313131;
    }
  }

  .role__container {
    width: 100%;
    height: 73vh;
    display: inline-flex;
    gap: 16px;
  }

  .dropdown-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10.5px;
    position: relative;
    padding: 8px 17px;

    & > span {
      font-size: 14px;
      color: #313131;
      cursor: pointer;
    }

    & > img {
      cursor: pointer;
      transform: rotate(-90deg);
      scale: 1.2;
    }
  }

  .child-list {
    display: flex;
    flex-direction: column;
  }

  .child-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding: 8px 17px;
    &:hover {
      background: #f8f8f9;
      transition: 150ms;
    }
    &.active {
      background: #f8f8f9;
      transition: 200ms;

      &:after {
        content: '';
        background: #00a3ff;
        border-radius: 0 2px 2px 0;
        position: absolute;
        transition: 200ms;
        height: 21px;
        width: 2px;
        top: 8px;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  .name-cont {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    margin-left: 20px;

    & > span {
      font-size: 14px;
      color: #313131;
    }
  }

  .no-padding {
    position: relative;
    top: -8px;
  }
</style>
