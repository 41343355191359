import { UserModel } from '@/models/user.model'
import { BranchModel } from '@/models/branch.model'

export class UserBranchModel {
  constructor(request = {}) {
    this.branch = new BranchModel(request.branch)
    this.user = new UserModel(request.user)
    this.storages = request.storages || { read: 'all', document_flow: 'all' }
    this.price_list = request.price_list || { read: 'all', edit: 'all' }
    this.objects = request.objects
  }
}
