var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "edit-role-modal"
    }
  }, [_c('b-modal', {
    attrs: {
      "id": "edit-role-modal",
      "centered": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function (_ref) {
        var close = _ref.close;
        return [_c('h5', [_vm._v(_vm._s(_vm.role.id ? 'Редактировать роль' : 'Новая роль'))]), _c('b-button', {
          staticClass: "close",
          on: {
            "click": close
          }
        }, [_c('img', {
          attrs: {
            "src": "/img/icons/modal_close.svg",
            "alt": ""
          }
        })])];
      }
    }, {
      key: "modal-footer",
      fn: function (_ref2) {
        var close = _ref2.close;
        return [_c('div', {
          staticClass: "d-flex w-50"
        }, [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.saveRole
          }
        }, [_vm._v(" Сохранить ")]), _c('b-button', {
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": close
          }
        }, [_vm._v(" Отмена ")])], 1)];
      }
    }])
  }, [_c('label', [_vm._v("Название")]), _c('b-input', {
    attrs: {
      "placeholder": "Введите название роли"
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }