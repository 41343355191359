var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "access-rights"
    }
  }, [_c('b-tabs', {
    attrs: {
      "nav-class": "min-tabs-width",
      "active-nav-item-class": "text-dark font-weight-bold"
    }
  }, [_c('role-tab'), _c('employee-tab')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }